import React, { useState } from "react"
import PropTypes from "prop-types"
import PrimaryLayout from "@src/layouts/primary"
import { UserContext } from "@src/context/user-context"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import Form, { FormActions } from "@src/components/form"
import FormFieldEmailAddress from "@src/components/form-field-email"
import FormFieldInputPassword from "@src/components/form-field-input-password"
import Button from "@src/components/core-button"
import Link, { navigate } from "@src/components/core-link"
const SignInPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const [email, setEmail] = useState(params.get("email"))
  const [success, setSuccess] = useState()
  const [formMessage, setFormMessage] = useState()
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("Login")} />
      <UserContext.Consumer>
        {({ signin }) => {
          const handleSubmit = ({ email, password }) => {
            signin(email, password)
              .then(() => {
                setSuccess(true)
                setFormMessage()
                setTimeout(() => {
                  navigate("/user/profile/")
                }, 3000)
              })
              .catch(error => {
                setSuccess(false)
                setFormMessage(error)
              })
          }
          return success ? (
            <ResponsiveSection variant="card">
              <h1>Successfully logged in!</h1>
              <p>Redirecting to your account profile.</p>
              <p>
                <Link to="/user/profile/">Click here to go there now</Link>
              </p>
            </ResponsiveSection>
          ) : (
            <ResponsiveSection variant="card">
              <h1>Log in</h1>
              <Form name="user-signin" onSubmit={handleSubmit} variant="blue">
                <FormFieldEmailAddress
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  label="Email address"
                  defaultValue={email}
                  onChange={setEmail}
                  validation={{
                    required: "Enter your email address to log in",
                  }}
                />
                <FormFieldInputPassword
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  label="Password"
                  type="password"
                  validation={{
                    required: "Enter your password to log in",
                  }}
                />
                <FormActions>
                  {formMessage && <p className="form-error">{formMessage}</p>}
                  <Button variant="rounded" type="submit">
                    Log in
                  </Button>
                  <p>
                    <Link
                      to={`/account/recovery/${
                        email ? "?email=" + encodeURIComponent(email) : ""
                      }`}
                    >
                      Forgot your password?
                    </Link>
                  </p>
                  <p>
                    <Link to="/account/signup/">Create a new account</Link>
                  </p>
                  <p>
                    Need to verify your account?{" "}
                    <Link to="/account/verification/">Get verified</Link>
                  </p>
                </FormActions>
              </Form>
            </ResponsiveSection>
          )
        }}
      </UserContext.Consumer>
    </PrimaryLayout>
  )
}
SignInPage.propTypes = {
  location: PropTypes.object,
}
export default SignInPage
